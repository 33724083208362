<template>
  <div id="app" style="{ backgroundColor: #003366}">
    <div id="week">
      {{ week }}
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "App",
  components: null,
  data() {
    return {
      week: null,
    };
  },
  mounted() {
    axios
      .get("/api/v1/week")
      .then((response) => (this.week = response.data.week))
      .catch((error) => {
        console.log(error);
        this.week = "?";
      });
  },
};
</script>

<style>
html, 
body {
    margin: 0;
    padding: 0;
    color: #ffffff;
    background-color: #003366;
}

#app {
  margin: 0;
  padding: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 18vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  text-shadow: 5px 5px 15px #333333;
}

#week {
  padding: 0;
  position: absolute;
  width: 25vh;
  height: 25vh;
  top: 50%;
  left: 50%;
  margin-left: -12.5vh;
  margin-top: -12.5vh;
}
</style>
